<template>
    <div class="container-fluid m-0">
        <section class="container head">
            <div class="title text-uppercase text-trade font-size-120">Vita Student</div>
            <div class="sub-title" style="margin-top: -20px">
                英国高端体验式
                <br>
                学生公寓
            </div>
            <div class="qrcode" style="position: absolute; right: 20px; top: 135px;">
                <img style="width: 170px; height: auto;" class="footer-img" decoding="async" loading="lazy" src="../../assets/images/qrcode_02.png">
            </div>
        </section>
        <section
            v-if="endDate >= new Date().getTime()"
            class="section-black p-t-8 p-b-10"
            style="position: sticky; top: 0px; z-index: 11">
            <countdown-time :endDate="endDate"></countdown-time>
        </section>
        <section class="head-panel container-fluid p-0">
            <div class="container-fluid">
                <div class="head-form">
                    <div class="form">
                        <div class="head-title">vita student</div>
                        <div class="head-sub-title">追求梦想的地方</div>

                        <div class="form-group">
                            <div class="form-label">姓名</div>
                            <input class="form-input" v-model="form.name" placeholder="姓名" type="text">
                        </div>
                        <div class="form-group">
                            <div class="form-label">手机</div>
                            <input class="form-input" v-model="form.cellphone" placeholder="手机" type="text">
                        </div>
                        <div class="form-group d-flex">
                            <el-checkbox class="me-2 checked" v-model="checked"></el-checkbox>
                            <div class="font-size-12">
                                成功提交后，我们将通过电话、微信或邮件方式联系您进行咨询Vita Student承诺采取合理手段保护用户提供的个人资料，在未得到用户许可前不将个人信息提供给第三方。
                            </div>
                        </div>
                        <div class="submit-btn m-t-20 cursor" @click="handleSubmit(1)">立即提交</div>

                    </div>
                </div>
                <div style="width: 100px;"></div>
            </div>
        </section>
        
        <section class="service-panel container p-0" v-if="servicesList && servicesList.length > 0">
            <div class="common-header m-0">
                <div class="common-title">全新升级的服务和体验</div>
            </div>
            <div class="service-panel-bg">
                <div class="common-header m-0">
                    <div class="common-note">
                        VITA STUDENT 作为英国高端学生公寓的典范代表，始终致力于为
                        <br>
                        我们的住户提供安全，舒适的入住体验。
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="right-panel">
                        <div class="carousel-container">
                            <swiper :options="swiperOption" ref="citySwiper">
                                <swiper-slide v-for="(item, ind) in servicesList" :key="ind">
                                    <div class="img-panel">
                                        <img class="img cursor" :src="item.wideThumbnailUrl" alt=""/>
                                        <div class="img-label">{{ item.titleCn }}</div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                            <div class="scroll-btn" style="top: -20px;">
                                <div class="btn-offset cursor icon iconfont" @click="prevSwiper()"> &#xe64c;</div>
                                <div class="btn-offset cursor icon iconfont" @click="nextSwiper()"> &#xe64d;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="featured-posts mt-0" v-if="apartments && apartments.length > 0">
            <div class="common-header container p-0 m-auto d-flex justify-content-between align-items-end">
                <div class="title-black">热门公寓</div>
                <div class="link-more w-auto cursor" @click="$goto('/cities')">
                    <div class="join-us text-uppercase text-black">更多公寓</div>
                    <div class="go-btn">
                        <span class="icon iconfont">&#xe60d;</span>
                    </div>
                </div>
            </div>
            <div class="container p-0 m-t-40">
                <div class="imgs row ms-0 me-0" style="margin: 0 -12px !important;">
                    <div @click="$goto(item.slug)" class="col-4" v-for="(item, index) in apartments" :key="index">
                        <div class="panel">
                            <div class="item">
                                <img class="img"
                                     :src="item.thumbnailUrl"
                                     alt="">
                            </div>
                            <div class="footer-panel">
                                <div class="footer-title font-size-26">
                                    {{ item.cnName }}
                                </div>
                                <div class="sub-title" v-html="item.address"></div>
                                <div class="icon-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="right-book-panel container p-0 pb-65" v-if="servicesList && servicesList.length > 0">
            <div class="d-flex justify-content-between align-items-end">
                <div class="common-header m-0">
                    <div class="common-title">VITA行前红宝书2024版</div>
                    <div class="common-note">
                        六大板块涵盖:
                        <br>
                        行前、落地、租房、生活、医疗、安全
                        <br><br>
                        这是一本独一无二的保姆级英国留学行前工具书，从行李准备到手续办理 <br>
                        从网购攻略到优秀学长学姐独门学习秘籍，无论是疫苗接种还是安全保障， <br>
                        事无巨细，帮你一键整理!
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between">
               
                <div class="right-panel m-t-12">
                    <div class="img-panel">
                        <img class="img img-aspect-ratio" src="https://vita-imgs.oss-cn-shanghai.aliyuncs.com/static/vsweb/landing-page-2-1.jpg" alt="">
                        <div class="img-label" @click="showDialog()">
                            <div class="link-more w-auto cursor">
                                <div class="join-us text-uppercase text-black">点击领取</div>
                                <div class="go-btn">
                                    <span class="icon iconfont">&#xe60d;</span>
                                </div>
                            </div>
                        </div>
                        <div class="line-read"></div>
                    </div>
                </div>
            </div>
        </section>
        <el-dialog width="400px" :visible.sync="showForm" :show-close="false" custom-class="redbook">
            <div class="dialog-body">
                <div class="head-form">
                    <div class="form">
                        <div class="head-title">vita student</div>
                        <div class="head-sub-title">行前红宝书2024版</div>

                        <div class="form-group">
                            <div class="form-label">姓名</div>
                            <input class="form-input" v-model="form.name" placeholder="姓名" type="text">
                        </div>
                        <div class="form-group">
                            <div class="form-label">手机</div>
                            <input class="form-input" v-model="form.cellphone" placeholder="手机" type="text">
                        </div>
                        <div class="form-group d-flex">
                            <el-checkbox class="me-2 checked" v-model="checked"></el-checkbox>
                            <div class="font-size-12">
                                成功提交后，我们将通过电话、微信或邮件方式联系您进行咨询Vita Student承诺采取合理手段保护用户提供的个人资料，在未得到用户许可前不将个人信息提供给第三方。
                            </div>
                        </div>
                        <div class="submit-btn m-t-20 cursor" @click="handleSubmit(2)">立即提交</div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
    
</template>

<script>
import { submitConsultsAPI, getServicesAPI } from '@/apis/common'
import countdownTime from '@/components/countdownTime/countdown-time';
export default {
    name: "landing-page",
    components: {
        countdownTime
    },
    data() {
        return {
            endDate: new Date("2024-11-12T08:00:00").getTime(),
            showForm: false,
            checked: true,
            form: {
                name: null,
                cellphone: null,
                source: 'pc-lp'
            },
            swiperOption: {
                pagination: false,
                autoplay: false,
                loop: true,
                slidesPerView: 2,
                spaceBetween: 10,
            },
            services: [],
            apartments: [
                {
                    thumbnailUrl: 'https://static.vitaalumnicn.com/uploads/20230910/b36dc88e6e0545a7169e16bfd62d3c20.jpg',
                    cnName: '爱丁堡',
                    address: '可步行到学校 <br> 体验爱丁堡网红景点 <br> Vita最受欢迎公寓之一',
                    slug: '/cities/edinburgh',
                },
                {
                    thumbnailUrl: 'https://static.vitaalumnicn.com/uploads/20230910/9c1c19e72da83b90d27c77e8e7573caf.jpg',
                    cnName: '利兹',
                    address: '地理位置优越 <br> 高端定制家具电器 <br> 高楼层视野广阔',
                    slug: '/cities/leeds',
                },
                {
                    thumbnailUrl: 'https://static.vitaalumnicn.com/uploads/20230910/a37b8585799d53fb97c9dd19595e1c5c.jpg',
                    cnName: '曼彻斯特',
                    address: '建筑群屡获殊荣 <br> 地址黄金地段 <br> 房源供不应求',
                    slug: '/cities/manchester',
                }
            ]
        }
    },
    created() {
        this.initData()
    },
    computed: {
        servicesList() {
            return this.services
        }
    },
    methods: {
        showDialog() {
            this.showForm = true;
        },
        initData() {
            getServicesAPI().then(res => {
                this.services = res.data.data
            });
            // getApartmentsAPI({isTop: true}).then(res => {
            //     this.apartments = res.data.data
            // })
        },
        handleSubmit(index) {
            if(this.checked === false) {
                this.$message({
                    type: 'warning',
                    message: this.$t('common.agreenChecked')
                })
                return
            }
            let form = this.form
            if(!(form.name && form.cellphone)) {
                this.$message({
                    type: 'warning',
                    message: this.$t('common.errorNotFillInfo')
                })
                return
            }
            if(index == 1) {
                form.source = 'pc-lp';
            }
            if(index == 2) {
                form.source = 'pc-lp-redbook';
            }
            submitConsultsAPI(form).then(res => {
                if (res.data.code === 200) {
                    this.$message({
                        type: 'success',
                        message: this.$t('common.successfully'),
                        
                    })
                    if(index == 2) {
                        window.location.href = 'https://vita-imgs.oss-cn-shanghai.aliyuncs.com/static/vsweb/data/vita-red-book.pdf';
                    }
                }
            })
        },
        prevSwiper() {
            this.$refs.citySwiper.swiper.slidePrev();
        },
        nextSwiper() {
            this.$refs.citySwiper.swiper.slideNext();
        },
    }
}
</script>

<style lang="less" scoped>
@import "./index.less";

</style>
